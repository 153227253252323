.navbar {
  position: fixed !important;
  top: 0;
  left: 100vw;
  width: 100vw;
  height: 100%;
  transition: left 0.8s;
  z-index: 5;
  background: black !important;
}

.navbar.active {
  left: 0;
}

.navbar nav h1 {
  position: relative;
  left: 150px;
  display: inline-block;
  margin: -1rem 0;
  color: var(--white);
  font-size: 6.5rem;
  opacity: 0;
  transition: left 1.5s, opacity 0.5s;
  cursor: pointer;
}

.navbar.active nav h1 {
  left: 0;
  opacity: 1;
  transition: left 1.5s, opacity 0.5s 0.5s;
}

nav {
  display: flex;
  flex-direction: column;
}

.navbar-footer {
  position: absolute;
  right: 3rem;
  bottom: 3rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  color: var(--white);
}

.navbar-footer img {
  height: 2rem;
  margin-bottom: 1rem;
}

@media screen and (min-width: 620px) {
  .navbar nav h1:before {
    content: "";
    position: absolute;
    bottom: 12%;
    left: 0;
    width: 100%;
    height: 0;
    background: var(--light-blue);
    transition: height 0.5s;
    mix-blend-mode: screen;
  }

  .navbar nav h1:hover:before {
    height: 80%;
  }
}

@media screen and (max-width: 620px) {
  aside .c-section {
    padding: 4rem 0 0 4rem;
  }

  .navbar nav h1 {
    font-size: 18vw;
  }
}

.c-hero {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.jektext {
  position: fixed;
  right: 50px;
}

.Menutext {
  position: fixed;
  left: 20px;
}

.c-hero-ov-text {
  width: 100%;
  height: 80%;
  display: flex;
  padding-top: 6vw;
}

.c-hero-ov-text h1,
.c-section h1 {
  font-size: 7rem;
  max-width: 900px;
}

.c-hero-ov-text h1 {
  animation-name: layer5;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-delay: 1s;
  opacity: 0;
  padding-left: 96px;
  padding-right: 96px;
}

@keyframes layer5 {
  0% {
      opacity: 0;
  }

  100% {
      opacity: 1;
  }
}

.c-hero-ov-text h1 span {
  color: var(--dark-blue);
  opacity: 0;
  transition: opacity 0.5s;
}

.c-hero-ov-text h1 span.active {
  opacity: 1;
}

@media screen and (max-width: 620px) {
  .c-hero-ov-text h1,
  .c-section h1 {
      font-size: 18vw;
  }

  .c-hero-ov-text h1 {
      padding-left: 42px;
      padding-right: 42px;
  }

  .c-hero-ov-text {
      padding-top: 16vw;
  }
}


@media screen and (max-height: 600px) {
  .c-section h1 {
      font-size: 15vh;
  }
}

.c-hero-ov-action {
  width: 100%;
  height: 24px;
  position: absolute;
  left: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  transition: transform 1.5s, color 1s;
  z-index: 101;
}

.c-hero-ov-action.still {
  bottom: 90px;
}

.c-hero-ov-action.active {
  position: fixed;
  top: 24px;
  mix-blend-mode: screen;
  color: var(--light-blue);
}

.c-hero-ov-action.safari {
  color: var(--dark-blue) !important;
  mix-blend-mode: normal;
  background: transparent;
}

.c-hero-ov-action p {
  text-align: center;
  max-width: 100px;
  animation-name: layer4;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-delay: 4.5s;
  opacity: 0;
  font-size: 1rem;
}

#action-text {
  cursor: pointer;
}

.c-hero-ov-action.selected {
  color: var(--light);
}

@keyframes layer4 {
  0% {
      opacity: 0;
  }

  100% {
      opacity: 1;
  }
}

.c-hero-ov-1 {
  position: absolute;
  top: 0;
  height: 100%;
  animation-name: layer1;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  background: var(--light-blue);
  mix-blend-mode: screen;
}

@keyframes layer1 {
  0% {
      width: 0%;
      left: 0;
  }

  50% {
      width: 100%;
      left: 0;
  }

  100% {
      width: 0%;
      left: 100%;
  }
}

.c-hero-ov-2 {
  position: absolute;
  top: 0;
  width: 100%;
  animation-name: layer2;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  background: var(--dark-blue);
  mix-blend-mode: screen;
  animation-delay: 0.5s;
}

@keyframes layer2 {
  0% {
      height: 0%;
      top: 0;
  }

  50% {
      height: 100%;
      top: 0;
  }

  100% {
      height: 0%;
      top: 100%;
  }
}

.c-c-hero-ov-3 {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 24px;
}

.c-hero-ov-3 {
  animation-name: layer3;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  border-radius: 100%;
  animation-delay: 1.2s;
  opacity: 0;
  height: 50px;
  width: 50px;
  background-color: var(--light-blue);
  transition: transform 1.5s, bottom 1s, width 1.5s, height 1.5s,
      background-color 1s;
  mix-blend-mode: normal;
  cursor: pointer;
  z-index: 101;
  margin-bottom: 24px;
}

.c-hero-ov-3.active {
  position: fixed;
  opacity: 1;
  z-index: 102;
  right: 15px;
  top: 24px;
  height: 24px;
  width: 24px;
}

.c-hero-ov-3.selected {
  background: var(--light);
  mix-blend-mode: normal;
}

.c-hero-ov-3.safari {
  animation-name: layer3;
  color: var(--dark-blue) !important;
  background: var(--dark-blue);
  mix-blend-mode: normal;
}

@keyframes layer3 {
  0% {
      opacity: 0;
      mix-blend-mode: screen;
  }

  99% {
      opacity: 1;
      mix-blend-mode: screen;
  }

  100% {
      opacity: 1;
  }
}

.c-hero-ov-4 {
  animation-duration: 2s;
  animation-fill-mode: forwards;
  transition: transform 1.5s, bottom 1s, width 1.5s, height 1.5s,
      background-color 1s;
  cursor: pointer !important;
  color: var(--light-blue);
  mix-blend-mode: normal;
  opacity: 0;
  z-index: 101;
  position: fixed;
  top: 24px;
  height: 24px;
  width: 0px;
  display: flex;
  align-items: center;
}

.c-hero-ov-4.selected {
  color: var(--light);
  mix-blend-mode: normal;
}

.c-hero-ov-4.safari {
  animation-name: none;
  color: var(--dark-blue) !important;
  mix-blend-mode: normal;
}

.c-hero-ov-4 p {
  font-size: 1rem;
}

@keyframes layer6 {
  0% {
      opacity: 0;
      mix-blend-mode: screen;
  }

  100% {
      opacity: 1;
      mix-blend-mode: screen;
  }
}

.c-hero-ov-4:hover {
  transform: translateY(-10px);
}

@media screen and (max-width: 400px) {
  .c-hero-ov-4 {
      opacity: 0;
  }
}

.scroll-down{
  position: relative;
  top: -100px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

.scroll-down-arrow{
  width: 2rem;
  height: 2rem;
  border-radius: 2rem;
  background-color: var(--light-blue);
}

.scroll-down-text{
  font-size: 1rem;
  font-family: SuisseIntlCond-Bold;
  color: var(--light-blue);
}