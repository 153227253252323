header {
  position: fixed;
  top: 0;
  z-index: 101;
  width: 100%;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem;
  color: var(--light-blue);
  mix-blend-mode: multiply;
}

.lightblue {
  color: var(--light-blue);
}

.menu-button {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.ball {
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 0.5rem;
  border-radius: 100%;
  background-color: var(--light-blue);
  mix-blend-mode: multiply;
}