/* GET IN TOUCH, NEWSLETTER */
.c-contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 6rem;
    background: var(--light-blue);
    color: var(--white);
}

/* PORTFOLIO */
.c-portfolio {
    padding: 6rem 0;
}

.c-project-slide-block {
    position: relative;
    padding: 0 5vw;
}

.c-project-slide-block img {
    height: 25rem;
    width: 37.5rem;
    object-fit: contain;
}

.c-project-slide-block .c-project-text {
    position: absolute;
    left: 0;
    top: 10%;
}

.hightlight {
    display: inline;
    padding: 0 0.25rem;
    color: var(--white) !important;
    background: var(--light-blue);
}

/* PARTNERS */
.c-partner {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 3rem;
}

.partner {
    width: 11rem;
    height: 11rem;
    background: var(--light) center no-repeat;
    background-size: 65%;
}

/* TESTIMONIAL */
.c-testimonial {
    margin: 6rem 0;
}

.c-testimonial h2 {
    padding-left: 6rem;
}

.c-testimonial article {
    min-width: 600px;
    white-space: normal;
    padding: 0 5%;
}

.c-testimonial article blockquote {
    margin-bottom: 2rem;
}

.c-testimonial article .hightlight {
    font-size: 1.5rem;
}

@media screen and (max-width: 840px) {
    .c-testimonial h2 {
        padding-left: 3rem;
    }
}

@media screen and (max-width: 550px) {
    .c-testimonial article {
        min-width: 80vw;
    }
}

/* SECTIONS */
.c-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 90vh;
    padding: 6rem;
}

.c-section.top-section {
    min-height: initial;
}

.c-section.centered {
    align-items: center;
}

.c-section h1,
.c-section h2 {
    max-width: 800px;
}

.c-section .content {
    max-width: 600px;
    background-color: var(--white);
}

.c-section .center {
    padding-top: 32px;
    width: 100%;
    max-width: 600px;
}

.c-section .content-center {
    display: flex;
    justify-content: center;
    width: 100%;
}

.c-section iframe {
    width: 100%;
}

.c-section .content p,
.sized-text {
    font-size: 1.5rem;
}

.video-highlight{
    margin-bottom: 1rem;
}

.image-title.invert {
    left: initial;
    right: -6rem;
}

@media screen and (max-width: 620px) {
    .c-section {
        padding: 5rem 2rem;
    }

    .c-section .content p,
    .sized-text {
        font-size: 1.2rem;
    }

    .c-partner{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-items: center;
    }

    .image-title.invert{
        right: 0;
    }
}

.c-video {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    width: 100%;
}

.c-video iframe,
.c-video object,
.c-video embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.c-jeker h1,
.c-jeker h2,
.c-jeker h3 {
    transition: color 0.5s;
}

.c-jeker {
    position: relative;
}

.c-section.invert {
    align-items: flex-end;
}

.image-content {
    position: absolute;
    top: 0;
    right: 0;
    width: calc(100% - 600px - 96px - 48px - 24px);
    height: calc(100% - 96px);
    max-height: 740px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.image-content.invert {
    right: initial;
    left: 0;
}

.team-image {
    background-image: url("https://i.imgur.com/EKGX7td.jpg");
}

.work-image {
    background-image: url("https://imgur.com/9SI4idk.jpg");
}

.image-title {
    position: absolute;
    bottom: 8rem;
    left: -6rem;
}

.image-title:hover {
    transform: scale(1.05, 1.05);
    transition: 0.5s;
}

@media screen and (max-width: 1190px) {
    .image-content {
        width: 100%;
        max-width: 600px;
        height: 40vh;
        margin: 0 auto;
        position: relative;
    }

    .c-jeker .content {
        margin-bottom: 48px;
    }

    .image-title {
        bottom: 0;
        left: 0;
    }
}