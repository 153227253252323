@font-face {
    font-family: SuisseIntl-Black;
    src: url(./font/SuisseIntl-Black-WebS.woff2);
}

@font-face {
    font-family: SuisseIntl-BlackItalic;
    src: url(./font/SuisseIntl-BlackItalic-WebS.woff2);
}

@font-face {
    font-family: SuisseIntlCond-Bold;
    src: url(./font/SuisseIntlCond-Bold-WebS.woff2);
}

@font-face {
    font-family: SuisseIntlCond-BoldItalic;
    src: url(./font/SuisseIntlCond-BoldItalic-WebS.woff2);
}

@font-face {
    font-family: SuisseIntl-Thin;
    src: url(./font/SuisseIntl-Thin-WebS.woff2);
}

@font-face {
    font-family: SuisseIntl-ThinItalic;
    src: url(./font/SuisseIntl-ThinItalic-WebS.woff2);
}

@font-face {
    font-family: SuisseIntl-Ultralight;
    src: url(./font/SuisseIntl-Ultralight-WebS.woff2);
}

@font-face {
    font-family: SuisseIntl-UltralightItalic;
    src: url(./font/SuisseIntl-UltralightItalic-WebS.woff2);
}

.s-title {
    font-family: SuisseIntl-Black;
}

.s-title-i {
    font-family: SuisseIntl-BlackItalic;
}


.b-title {
    font-family: SuisseIntlCond-Bold;
}

.b-title-i {
    font-family: SuisseIntlCond-BoldItalic;
}


.s-text {
    font-family: SuisseIntl-Thin;
}

.s-text-i {
    font-family: SuisseIntl-ThinItalic;
}


.b-text {
    font-family: SuisseIntl-Ultralight;
}

.b-text-i {
    font-family: SuisseIntl-UltralightItalic;
}

* {
    --dark-blue: #1690C3;
    --light-blue: #7CCCED;
    --light: #FAFAFA;
    --medium: #7C7C7C;
    --white: #FFF;
    --black: #000;
}


blockquote {
    position: relative;
}

html,
body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}