.departments {
    margin: 0 6vw 6rem;
}

.departments-nav {
    display: flex;
    margin-bottom: 1rem;
    font-size: 1.5rem;
    overflow: auto;
    gap: 1rem;
}

.jeker-line{
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}

.dep {
    padding-right: 6vw;
    cursor: pointer;
}

.departments.dir .dep.dir,
.departments.all .dep.all,
.departments.dir .jeker-dep,
.departments.all .jeker-dep,
.departments.dir .jeker-role,
.departments.all .jeker-role {
    color: rgb(124, 204, 237);
}

.departments.inov .dep.inov,
.departments.inov .jeker-dep,
.departments.inov .jeker-role {
    color: rgb(227, 113, 59);
}

.departments.tech .dep.tech,
.departments.tech .jeker-dep,
.departments.tech .jeker-role {
    color: rgb(81, 164, 80);
}

.departments.int .dep.int,
.departments.int .jeker-dep,
.departments.int .jeker-role {
    color: rgb(171, 51, 74);
}

@media only screen and (max-width: 772px) {
    .departments {
        font-size: 1.25rem;
        margin: 0vw 3vw 1rem;
    }

    .dep {
        padding-right: 2vw;
    }
}

@media screen and (max-width: 350px) {
    .departments {
        font-size: 1rem;
    }
}

.c-jekers {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
    gap: 3vw;
}

.jeker {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.placeholder{
    width: 100%;
    height: 100%;
}

.jeker-content-container {
    width: 80%;
    display: flex;
    flex-direction: column;
    margin: -6rem 0 -1rem -1rem;
    padding: 1rem;
    background-color: var(--white);
    filter: drop-shadow(0 0 30px rgba(105, 104, 104, 0.15));
    gap: 0.5rem;
}

.jeker-role {
    font-size: 1rem !important;
}

.jeker-dep {
    font-size: 0.8rem !important;
}

.jeker-contacts {
    display: flex;
    align-self: flex-end;
}

.jeker-contacts a {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 0.25rem;
}

.jeker-contacts a img {
    width: inherit;
}

@media screen and (max-width: 860px) {
    .jeker {
        height: 25rem;
    }
}

.logo-loader {
    display: flex;
    justify-content: center;
    padding-bottom: 60px;
}

.logo {
    width: 50px;
    -webkit-animation: spin 5s linear infinite;
    -moz-animation: spin 5s linear infinite;
    animation: spin 5s linear infinite;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@media screen and (max-width: 620px) {
    .c-jekers {
        gap: 6vh;
    }
}