.event-section{
    gap: 1.5rem;
    padding: 6rem 12rem;
}

.event-info{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 1rem;
}

.event-description{
    font-size: 1.5rem;
}

.event-grid-images{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;
}

.event-image{
    width: 100%;
    height: 100%;
    grid-column: span 1;
}

.event-redirect{
    font-family: SuisseIntlCond-Bold;
    font-size: 1.5rem;
    margin: 32px 0 0 0;
}

@media screen and (max-width: 620px) {
    .event-section{
        padding: 6rem 2rem;
    }

    .event-grid-images{
        grid-template-columns: repeat(1, 1fr);
    }
    
}