footer {
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2rem;
  align-items: flex-end;
  justify-content: space-between;
  padding: 3rem;
  color: var(--white);
  background: var(--black);
}

.footer-address>div:last-child {
  display: flex;
  flex-direction: column;
}

.footer-address img {
  width: 1.5rem;
  margin-right: 0.25rem;
}

.footer-address a:last-child img {
  background-color: var(--white);
}

.footer-middle {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 3rem;
  text-align: center;
}

.footer-contacts {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.footer-contacts img {
  height: 2rem;
  margin-bottom: 1rem;
}

@media screen and (max-width: 740px) {
  footer {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .footer-address {
    order: 2;
  }

  .footer-middle {
    grid-column-start: 1;
    grid-column-end: 3;
    order: 1;
  }

  .footer-contacts {
    order: 3;
  }
}

@media screen and (max-width: 450px) {
  footer {
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr;
  }

  .footer-address {
    text-align: center;
  }

  .footer-middle {
    grid-column-start: 1;
    grid-column-end: 1;
    padding: 0;
  }

  .footer-contacts {
    align-items: center;
  }
}