#root{
    min-height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.hero-section {
    padding: 6rem;
}

.hero-section h1 {
    color: var(--dark-blue);
    font-size: 400%;
    font-family: SuisseIntlCond-Bold;
    margin-bottom: -10px;
}

.hero-section h2 {
    font-size: 200%;
    font-family: SuisseIntlCond-Bold;
}

.hero-section > a {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 0.75rem;
    border-radius: 16px;
    background-color: white;
    border: 2px solid var(--dark-blue);
    color: var(--dark-blue);
    font-size: 1.5rem;
    font-family: SuisseIntlCond-Bold;
    cursor:pointer;
    width:fit-content
}

.hero-section > a:hover {
    background-color: var(--dark-blue);
    color:white !important
}



.hero-section h3 {
    font-size: 150%;
    font-family: SuisseIntlCond-Bold;
}

.c-recruit {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 3rem;
}

.image-gallery-image {
    object-fit: cover !important;
    padding: 10%;
}

.image-gallery-icon {
    filter: none !important;
    color: var(--dark-blue) !important;
}

.image-gallery-bullet {
    border: 0 !important;
    background: var(--light-blue) !important;
    box-shadow: none !important;
}

.image-gallery-bullet.active {
    background: var(--dark-blue) !important;
}

.countdown-container {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    text-align: center;
}

li {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 5rem;
    min-height: 5em;
    margin: 0.5rem !important;
    color: var(--dark-blue);
    background-color: var(--white);
    font-family: SuisseIntlCond-Bold;
    list-style-type: none;
}

.countdown-container span:first-child {
    font-size: 2rem;
}

.t-form {
    padding: 3rem;
    text-align: center;
}

.t-form p:first-child {
    font-size: 2rem;
    font-family: SuisseIntlCond-Bold;
}

.t-form p:nth-child(2) {
    margin: 0.5rem 0;
    font-size: 1.5rem;
}

.footer-recruitment{
    position: fixed;
    bottom: 0;
    width: 100%;
}

@media screen and (max-width: 620px) {
    .hero-section {
        padding: 4rem;
    }
}