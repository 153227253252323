.h-section {
  padding: 6rem;
}

.h-section h1 {
  color: var(--light-blue);
  font-size: 4em;
}

.h-section h2 {
  font-size: 3rem;
}

.hello-team-photo {
  width: 60vw;
  margin-top: 15vh;
}

.hello-contacts {
  position: absolute;
  right: 10vw;
  width: 30vw;
  padding: 2rem;
  background-color: var(--white);
  filter: drop-shadow(3px 3px 5px var(--medium));
  font-weight: bold;
}

.hello-contacts .contact {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  font-family: SuisseIntl-Thin;
}

.hello-contacts .contact img {
  margin-right: 1rem;
}

.h-section h3 {
  color: var(--dark-blue);
  font-size: 2.5rem;
  font-family: SuisseIntlCond-Bold;
}

.f-section {
  width: 50%;
  display: flex;
  flex-direction: column;
  margin: 3rem auto 0;
}

.f-section h3 {
  color: var(--black);
}

.f-section label {
  font-weight: bold;
  margin: 1rem 0 0.25rem;
}

.f-section input, .f-section textarea {
  padding: 0.75rem 0.5rem;
  border-radius: 0.25rem;
  border: 1px solid var(--medium);
}

.f-section button {
  align-self: flex-end;
}

.f-section .captcha {
  margin: 1rem 0;
}

@media screen and (max-width: 950px) {
  .h-section {
    padding: 5rem;
  }

  .h-section h2 {
    font-size: 2.5rem;
  }

  .h-section h3 {
    font-size: 1.5rem;
  }

  .hello-contacts {
    position: revert;
    width: 100%;
    margin-top: 2rem;
  }

  .hello-team-photo {
    object-fit: cover;
    width: 100%;
    margin-top: 2rem;
  }

  .f-section {
    width: 80%;
  }
}

@media screen and (max-width: 550px) {
  .h-section {
    padding: 6rem 2rem 2rem;
  }

  .h-section h1 {
    font-size: 3rem;
  }

  .h-section h2 {
    font-size: 2rem;
  }

  .hello-contacts {
    padding: 1.5rem;
  }

  .hello-team-photo {
    display: none;
  }

  .f-section {
    width: 100%;
  }
}

@media screen and (max-width: 350px) {
  .hello-contacts {
    padding: 1rem;
  }
}
